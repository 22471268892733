import React from 'react'
import ResponsivePlayer from '../Video/ResponsivePlayer'
import { Container, Row, Col } from 'react-bootstrap'
import './MienBac.css'
import { Link, useParams } from 'react-router-dom'

function MienBac() {
  let { id } = useParams()
  let homeUrl = '/'
  if (id) {
    homeUrl = '/' + id
  }
  return (
    <div>
      <h1 class="header-title">Phần mềm tính bảng Miền Bắc</h1>
      <p class="header-title-text">Dành cho cách chơi theo kiểu miền bắc</p>

      <Container>
        <Row>
          <Col>
            <h1 className="header-title-text">
              1️⃣ Bước 1: Tải, Cài Đặt Ứng Dụng
            </h1>
            <ResponsivePlayer url="https://youtu.be/OE3yDuAiK1s" />
          </Col>
        </Row>

        <Row>
          <Col>
            <br />
            <h2 className="header-title-text">
              {' '}
              2️⃣ Bước 2: Thêm Khách Hàng, Công Nợ
            </h2>
            <ResponsivePlayer url="https://youtu.be/SMLjRb13-KQ" />
          </Col>
        </Row>

        <Row>
          <Col>
            <br />
            <h4 className="header-title-text"> 3️⃣ Bước 3: Xử Lý Tin Nhắn</h4>
            <ResponsivePlayer url="https://youtu.be/K4vyFa8Gww8" />
          </Col>
        </Row>
        <Row>
          <Col>
            <Link
              to={homeUrl}
              style={{ margin: '20px 0 5px 0' }}
              className="nav-link learn-more-btn"
            >
              Quay lại trang chủ
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default MienBac
