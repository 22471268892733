import React from 'react'
import ResponsivePlayer from '../Video/ResponsivePlayer'
import { Container, Row, Col } from 'react-bootstrap'
import './BaMien.css'
import { Link, useParams } from 'react-router-dom'

function BaMien() {
  const { id } = useParams()
  let homeUrl = '/'
  if (id) {
    homeUrl = '/' + id
  }
  return (
    <div>
      <h1 class="header-title">
        🎁 ỨNG DỤNG TÍNH TIỀN LOTO MIỀN NAM #PMKETOAN
      </h1>
      <p class="header-title-text">Dành cho cách chơi theo kiểu miền Nam</p>

      <Container>
        <Row>
          <Col>
            <h1 className="header-title-text">
              1️⃣ Bước 1: Hướng dẫn "Tải và cài đặt Ứng Dụng"
            </h1>
            <ResponsivePlayer
              // url="https://youtu.be/DdkfH95XFxk"
              url="https://youtu.be/mCCACp-crLs"
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <h1 className="header-title-text">
              2️⃣ Bước 2: Hướng dẫn "Thêm khách hàng"
            </h1>
            <ResponsivePlayer url="https://youtu.be/W3d8Zn_hRNE" />
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <h1 className="header-title-text">
              3️⃣ Bước 3: Xem Video Xử lý tin nhắn ở đây
            </h1>
            <ResponsivePlayer url="https://youtu.be/n1s-S5KGlCE" />
          </Col>
        </Row>
        <Row>
          <Col>
            <Link
              to={homeUrl}
              style={{ margin: '20px 0 5px 0' }}
              className="nav-link learn-more-btn"
            >
              Quay lại trang chủ
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default BaMien
