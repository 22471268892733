import React from 'react'
import './App.css'
// import { ReactComponent } from '*.svg';
import MienBac from '../src/components/MienBac/MienBac'
import BaMien from '../src/components/BaMien/BaMien'
import Home from '../src/components/Home/Home'

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

class App extends React.Component {
  render() {
    return (
      <div className="App">
        <Router>
          <div>
            {/*
          A <Switch> looks through all its children <Route>
          elements and renders the first one whose path
          matches the current URL. Use a <Switch> any time
          you have multiple routes, but you want only one
          of them to render at a time
        */}
            <Switch>
              <Route path="/mienbac/:id">
                <MienBac />
              </Route>
              <Route path="/mienbac">
                <MienBac />
              </Route>
              <Route path="/ba-mien/:id">
                <BaMien />
              </Route>
              <Route path="/ba-mien">
                <BaMien />
              </Route>
              <Route
                exact
                path="/:id"
                render={(props) => <Home id={props.match.params.id} />}
              />
              <Route exact path="/">
                <Home />
              </Route>
            </Switch>
          </div>
        </Router>
      </div>
    )
  }
}

export default App
